<template src="./FeedbackForm.html"></template>

<script>
import networkManager from "../NetworkManager/index";
import showStatus from "../NetworkManager/showStatus";

export default {
  name: "FeedbackForm",

  data: () => ({
    backgroundColor: "#E1E2F8",
    instituteId: "",
    instituteLogo: "",
    formUniqueUrlId: "",
    formId: "",
    loading: true,
    formTitle: "",
    dueDate: "",
    startDate: "",
    cbIsNBACriteriaAttainment: "",
    selectedAns: "",
    questionList: [],
    qOptions: [],
    response: [],
    invalidUrl: "INVALID_URL",
    formType: "",
    feedback: [],
    feedbackForm: [],
    allStudents: [],
    name: "",
    email: "",
    uId: "",
    formDescription: "",
    valid: false,
    emailchecked: null,
    isSubjectPresent: null,
    responsechecked: false,
    isShow: null,
    isSubmit: false,
    feedbackMessage: "",
    feedbackMessageType: "",
    isCourseAvaliable: null,
    collegePRNNo: "",
    fullName: "",
    isSingleForAllCourse: "",
    isAllowToSubmitData: undefined,
    isLoadingData: false,
    isFeedbackClosed: null,
    isFeedbackAvailable: null,
    errorMessages: [],
    isEmailValidate: false,
    errorDialog: false,
    isQuestionAvailable: null,
  }),
  computed: {
    emailRules() {
      return [
        (v) => !!v || "Email is required",
        (v) => /.+@.+\..+/.test(v) || "Email must be valid",
      ];
    },
  },
  created() {
    this.getFormId();
  },
  methods: {
    async getInstituteLogo() {
      try {
        const res = await networkManager.getRequest(
          "/institute/getInstituteBranding",
          {
            instituteId: this.instituteId,
          }
        );
        this.instituteLogo = res.instituteLogoLink;
      } catch (e) {
        console.error(e);
      }
    },
    getFormId() {
      const url = window.location.href;
      const lastParam = url.split("/").slice(-1)[0];

      if (lastParam === "") {
        this.invalidUrl = "INVALID_URL";
      }

      this.formUniqueUrlId = lastParam;
      this.getFormDetails();
      this.invalidUrl = "FORM_INPROGRESS";
    },
    isPastTime(isoTime) {
      const inputTime = new Date(isoTime);
      const currentTime = new Date();

      return inputTime < currentTime;
    },
    UTCtoISTFormat(startDate) {
      // Convert to a Date object in UTC
      const utcDate = new Date(startDate);

      // Convert to IST (UTC +5:30)
      const istOffset = 5.5 * 60 * 60 * 1000; // 5.5 hours in milliseconds
      const istDate = new Date(utcDate.getTime() + istOffset);

      // Format to a readable string
      const istDateTimeStr = istDate
        .toISOString()
        .slice(0, 19)
        .replace("T", " ");

      return istDateTimeStr;
    },
    async getFormDetails() {
      try {
        const res = await networkManager.getRequest(
          "/feedbackWithNBAAttainment/getFeedbackByFormUrl",
          {
            formURL: this.formUniqueUrlId,
          }
        );
        const feedbackData = res.feedback[0];
        if (res.feedback?.length > 0) {
          let dueDate =
            feedbackData.formType === "Course Feedback" &&
            feedbackData.extendedDate !== null
              ? feedbackData.extendedDate
              : feedbackData.endDate;

          this.isFeedbackClosed = this.isPastTime(dueDate);
          this.isFeedbackAvailable = this.isPastTime(feedbackData.startDate);

          this.startDate = this.UTCtoISTFormat(feedbackData.startDate);
          this.instituteId = feedbackData.instituteId;
          this.formType = feedbackData.formType;
          this.feedback = feedbackData;
          this.formId = feedbackData._id;
          this.formTitle = feedbackData.formTitle;
          this.formDescription = feedbackData.formDescription;
          this.isSingleForAllCourse = feedbackData.setting.isSingleForAllCourse;
          this.dueDate = new Date(feedbackData.endDate).toLocaleDateString();

          // !this.isSingleForAllCourse
          this.isCourseAvaliable =
            this.formType === "Course Feedback" &&
            this.feedback?.courses?.length > 0
              ? true
              : false;

          const extractQuestions = (questions, res = []) => {
            questions.forEach((question) => {
              if (question.question && question.question.length > 0) {
                extractQuestions(question.question, res);
              } else {
                res.push({
                  questionId: question.questionId,
                  questionNumber: question.questionNumber,
                  questionStatement: question.questionStatement,
                  selectedType: question?.scales?.selectedType,
                  selectedAnswer: [],
                  selectedScaleOrOption:
                    question?.scales?.selectedScaleOrOption,
                  options: question?.scales?.scale?.map(
                    (obj) => obj.scaleLabel
                  ),
                });
              }
            });
          };
          if (this.formType === "Course Feedback") {
            this.feedbackForm = this.feedback?.courses?.map((course) => {
              let res = [];
              let section = "";
              // course?.allQuestions?.forEach((item1) => {
              //   section =
              //     item1.question !== undefined
              //       ? item1.question[0].questionStatement
              //       : "";
              //   extractQuestions(item1.question, res);
              // });
              extractQuestions(course?.allQuestions, res);
              this.isQuestionAvailable = res.length > 0 ? true : false;

              return {
                subjectId: course.subjectId,
                subjectName: course.subjectName,
                sectionName: section,
                questions: res,
              };
            });
          } else {
            let res = [];
            // this.feedback.allQuestions.forEach((item1) => {
            //   extractQuestions(item1.question, res);
            // });
            extractQuestions(this.feedback.allQuestions, res);
            this.feedbackForm = res;
            this.isQuestionAvailable = res.length > 0 ? true : false;
          }

          this.getInstituteLogo();
          if (this.formId === undefined) {
            this.invalidUrl = "INVALID_URL";
          }
        } else {
          this.invalidUrl = "INVALID_URL";
        }

        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.invalidUrl = "INVALID_URL";
        showStatus(err, 6000, "error", this);
      }
    },
    async checkForAuthenticatedUser(email) {
      await networkManager.getRequest(
        "/feedbackResponse/checkForAuthenticatedUser",
        {
          semId: this.feedback.semId,
          department: this.feedback.department,
          courseYear: this.feedback.courseYear,
          email: email.trim(),
        }
      );
    },
    async validateEmail() {
      this.isShow = null;
      this.emailchecked = null;
      this.feedbackMessage = "";
      this.errorMessages = [];
      this.emailRules.forEach((rule) => {
        const result = rule(this.email);
        if (result !== true) {
          this.errorMessages.push(result);
        }
      });

      this.isEmailValidate = /.+@.+\..+/.test(this.email.trim());
      if (!this.isEmailValidate) {
        this.emailchecked = null;
        return;
      }
      // await this.validateData();
    },
    async validateData() {
      this.feedbackMessage = "";
      this.isLoadingData = true;

      const isAuthorized = await this.CheckEmail();
      if (!isAuthorized) {
        this.emailchecked = false;
        this.isSubjectPresent = false;
        this.isLoadingData = false;
      }
      if (isAuthorized) {
        await this.getUsers();
        const isSubjectApplicable = await this.checkedSubjectIsApplicable();

        if (!isSubjectApplicable) {
          this.isSubjectPresent = false;
          this.isLoadingData = false;
        }
        const isResponseAvailable = await this.checkedResponseIsAvaliable();
        if (!isResponseAvailable) {
          this.responsechecked = false;
        }
      }
      await this.finalChecking();
    },
    async CheckEmail() {
      try {
        this.uId = "";

        // Fetch user authentication by email
        const response = await networkManager.getRequest(
          "/auth/getUserAuthenticationByEmail",
          {
            email: this.email.trim(),
          }
        );
        // Check if user authentications exist
        if (response?.userAuthentications?.length > 0) {
          const { uId } = response.userAuthentications[0];
          this.uId = uId;
          this.emailchecked = true;

          return true;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    async getUsers() {
      try {
        // Fetch user details
        const users = await networkManager.postRequest("/users/getUsers", {
          uIds: [this.uId],
        });

        this.collegePRNNo = users.users[0]?.collegePRNNo;
        this.fullName = `${users.users[0]?.firstName || ""} ${
          users.users[0]?.middleName || ""
        } ${users.users[0]?.lastName || ""}`.trim();
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    extractDateTime(dateTime) {
      if (!dateTime) return { date: "", time: "" }; // Handle undefined dates
      const dateObject = new Date(dateTime);

      const [date, time] =
        dateObject !== "Invalid Date"
          ? dateObject?.toISOString().split("T")
          : "";

      return {
        date,
        time: time.split("Z")[0].slice(0, 5), // Extract only the hours and minutes
      };
    },
    async checkedSubjectIsApplicable() {
      try {
        const objToPush = {
          semId: this.feedback.semId,
          department: this.feedback.department,
          courseYear: this.feedback.courseYear,
          uId: this.uId,
        };

        if (this.isCourseAvaliable) {
          objToPush.subjectIds =
            this.feedback?.courses?.map((item) => item.subjectId) || [];
        }

        const { semesterUsers } = await networkManager.getRequest(
          "/semesterUser/getSubjectsByUId",
          objToPush
        );
        if (semesterUsers?.length > 0) {
          if (this.isCourseAvaliable && objToPush.subjectIds.length) {
            const availableSubjects = new Set(
              semesterUsers
                .flatMap((user) => user.subjects)
                .filter((subject) => objToPush.subjectIds.includes(subject))
            );

            const finalSub = this.feedbackForm.filter((item) =>
              availableSubjects.has(item.subjectId)
            );
            this.feedbackForm = finalSub;
          }
          this.isSubjectPresent = semesterUsers.length > 0 ? true : false;
          return true;
        } else {
          this.isSubjectPresent = false;
        }
      } catch (e) {
        console.error(e);
        this.isSubjectPresent = false;
        return false;
      }
    },
    async checkedResponseIsAvaliable() {
      try {
        const objToPush = {
          feedbackFormId: this.feedback.feedbackId,
          uId: this.uId,
        };
        if (this.isCourseAvaliable) {
          objToPush["subjectIds"] = this.feedback.courses.map(
            (item) => item.subjectId
          );
        }
        const res = await networkManager.getRequest(
          "/feedbackResponse/getFeedbackResponseByUId",
          objToPush
        );
        this.responsechecked = res.feedbacks.length > 0 ? true : false;
        return true;
      } catch (e) {
        console.error(e);
        this.responsechecked = false;
        return false;
      }
    },
    async finalChecking() {
      this.isShow =
        this.emailchecked && !this.responsechecked && this.isSubjectPresent;

      if (this.emailchecked && this.isSubjectPresent && this.responsechecked) {
        this.feedbackMessage = "The response has already been taken!";
        this.feedbackMessageType = "success";
        this.errorDialog = true;
      }
      if (!this.emailchecked || (this.emailchecked && !this.isSubjectPresent)) {
        this.feedbackMessage = "Unauthorized User";
        this.feedbackMessageType = "error";
        this.errorDialog = true;
      }
      this.isLoadingData = false;
    },
    // checkSelectedAnswers(data) {
    //   if (this.formType === "Course Feedback") {
    //     for (const subject of data) {
    //       for (const question of subject.questions) {
    //         // Check if selectedAnswer is an empty array or an empty string
    //         if (
    //           (Array.isArray(question.selectedAnswer) &&
    //             question.selectedAnswer.length === 0) ||
    //           (typeof question.selectedAnswer === "string" &&
    //             question.selectedAnswer === "")
    //         ) {
    //           question.errorMessage = "This is a required question.";
    //           return false;
    //         } else {
    //           delete question.errorMessage;
    //         }
    //       }
    //     }
    //     return true;
    //   } else {
    //     for (const subject of data) {
    //       // Check if selectedAnswer is an empty array or an empty string
    //       if (
    //         (Array.isArray(subject.selectedAnswer) &&
    //           subject.selectedAnswer.length === 0) ||
    //         (typeof subject.selectedAnswer === "string" &&
    //           subject.selectedAnswer === "")
    //       ) {
    //         subject.errorMessage = "This is a required question.";
    //         return false;
    //       } else {
    //         delete subject.errorMessage;
    //       }
    //     }
    //     return true;
    //   }
    // },
    checkSelectedAnswers(data) {
      let allAnswersFilled = true;

      if (this.formType === "Course Feedback") {
        for (const subject of data) {
          for (const question of subject.questions) {
            // Check if selectedAnswer is an empty array or an empty string
            if (
              (Array.isArray(question.selectedAnswer) &&
                question.selectedAnswer.length === 0) ||
              (typeof question.selectedAnswer === "string" &&
                question.selectedAnswer === "")
            ) {
              question.errorMessage = "This is a required question.";
              allAnswersFilled = false;
            } else {
              delete question.errorMessage;
            }
          }
        }
      } else {
        for (const subject of data) {
          // Check if selectedAnswer is an empty array or an empty string
          if (
            (Array.isArray(subject.selectedAnswer) &&
              subject.selectedAnswer.length === 0) ||
            (typeof subject.selectedAnswer === "string" &&
              subject.selectedAnswer === "")
          ) {
            subject.errorMessage = "This is a required question.";
            allAnswersFilled = false;
          } else {
            delete subject.errorMessage;
          }
        }
      }

      return allAnswersFilled;
    },
    async submitFeedbackResponse() {
      try {
        this.isAllowToSubmitData = this.checkSelectedAnswers(this.feedbackForm);

        if (this.isAllowToSubmitData) {
          let response = [];
          let objToPush = {
            candidateFullName: this.fullName,
            candidateEmailId: this.email.trim(),
            feedbackFormId: this.formUniqueUrlId,
            uId: this.uId,
          };

          const mapQuestions = (questions) =>
            questions.map((question) => ({
              answer: question.selectedAnswer,
              questionId: question.questionId,
              questionNumber: question.questionNumber,
            }));

          if (this.isCourseAvaliable) {
            response = this.feedbackForm.map((subject) => ({
              ...objToPush,
              subjectId: subject.subjectId,
              studentResponse: mapQuestions(subject.questions),
            }));
          } else {
            let studentResponse = mapQuestions(this.feedbackForm);
            response.push({
              ...objToPush,
              studentResponse,
            });
          }

          const res = await networkManager.postRequest(
            "/feedbackWithNBAAttainment/saveResponse",
            {
              feedbackFormId: this.formUniqueUrlId,
              studentResponse: response,
            }
          );
          await res;
          this.invalidUrl = "FORM_SUBMITTED_SUCCESS";
          this.isSubmit = true;
          this.feedbackMessage = "Thank you for your feedback!";
          alert("Form submitted Successfully!!");
          showStatus("Form submitted Successfully!!", 6000, "success", this);
        }
      } catch (err) {
        showStatus(err, 6000, "error", this);
      }
    },
    async submitForm() {
      let flag = false;
      this.response.map((item) => {
        if (!item.answer) {
          flag = true;
        }
      });
      if (!flag) {
        //Submit Feedback data
        this.response.map((item) => {
          if (!this.cbIsNBACriteriaAttainment) {
            if (item.questionType === "Multiple Choice Question") {
              delete item.options;
            }
          } else {
            delete item.options;
          }
        });
        try {
          const res = await networkManager.postRequest(
            "/feedbackWithNBAAttainment/saveResponse",
            {
              feedbackFormId: this.formId,
              studentResponse: this.response,
            }
          );
          await res;
          this.invalidUrl = "FORM_SUBMITTED_SUCCESS";
          alert("Form submitted Successfully!!");
          showStatus("Form submitted Successfully!!", 6000, "success", this);
        } catch (err) {
          showStatus(err, 6000, "error", this);
        }
      } else {
        alert("Fill blank fields");
        showStatus("Fill blank fields", 6000, "error", this);
      }
    },
  },
};
</script>

<style src="./FeedbackForm.css" scoped></style>
